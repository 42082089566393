import Vue from 'vue'
import VueRouter from 'vue-router'
import {
	getToken,
	save,
	get
} from "@/utils/auth";
import {
	getUserInfo
} from "@/api/User";
import store from "@/store";

import ws from "@/api/common/socket/vue-socket"

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Layout',
	redirect: "/main",
	component: () => import( /* webpackChunkName: "Layout" */ '../views/Layout'),
	children: [{
			path: 'main',
			name: 'Main',
			meta: {
				noAuth: true
			},
			component: () => import( /* webpackChunkName: "Main" */ '../views/Main'),
		},
		{
			path: 'mall',
			name: 'mall',

			component: () => import('../views/mall'),
		},

		{
			path: 'goodsDetail',
			name: 'goodsDetail',

			component: () => import('../views/mall/goodsDetail'),

		},
		{
			path: 'goodsNormalDetail',
			name: 'goodsNormalDetail',
			component: () => import('../views/mall/goodsNormalDetail'),
		},

		{
			path: 'goodsPay',
			name: 'goodsPay',
			component: () => import('../views/mall/goodsPay'),

		},
		{
			path: 'goodsNormallPay',
			name: 'goodsNormallPay',
			component: () => import('../views/mall/goodsNormallPay'),

		},
        {
			path: '/website',
			name: 'website',
			component: () => import('../views/website/index'),
		},

		{
			path: '/vipCenter',
			name: 'vipCenter',
			redirect: "/index",
			component: () => import('../views/vipCenter/components/MenuLeft'),
			children: [{
					path: '/index',
					name: 'index',
					component: () => import('../views/vipCenter/index'),
				}, {
					path: '/exchangeGifts',
					name: 'exchangeGifts',
					component: () => import('../views/vipCenter/exchangeGifts'),
				},
				{
					path: '/CustomDesign',
					name: 'CustomDesign',
					component: () => import('../views/vipCenter/CustomDesign'),
				},
				{
					path: '/recharge',
					name: 'recharge',
					component: () => import('../views/vipCenter/recharge'),
				},


				, {
					path: '/serive',
					name: 'serive',
					component: () => import('../views/vipCenter/serive'),
				},
				{
					path: '/preinstall',
					name: 'preinstall',
					component: () => import('../views/vipCenter/preinstall'),
				},



			]
		},



		{
			path: 'normal-info',
			name: 'NormalInfo',
			component: () => import( /* webpackChunkName: "NormalInfo" */ '../views/NormalGoodsView'),
		},
		{
			path: 'login',
			name: 'Login',
			meta: {
				noAuth: true
			},
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login'),
		},
		{
			path: 'accout',
			name: 'accout',
			meta: {
				noAuth: true
			},
			component: () => import('../views/Register/accout'),
		},
		{
			path: 'sentInvoice',
			name: 'sentInvoice',
			meta: {
				noAuth: true
			},
			component: () => import('../views/Register/sentInvoice'),
		},
		{
			path: 'InviteCode',
			name: 'inviteCode',
			meta: {
				noAuth: true
			},
			component: () => import('../views/Register/InviteCode'),
		},
		{
			path: 'userhome',
			name: 'UserHome',
			component: () => import( /* webpackChunkName: "UserHome" */ '../views/UserCenter'),
		},

		{
			path: 'userhome/usercenter/gifts',
			name: 'gifts',
			component: () => import( /* webpackChunkName: "Gifts" */ '../views/UserCenter/exchangeGifts'),
		},
		{
			path: 'userhome/ordercenter',
			name: 'OrderCenter',
			component: () => import( /* webpackChunkName: "OrderCenter" */ '../views/OrderCenter'),
		},
		{
			path: 'userhome/ordercenter/comment',
			name: 'OrderComment',
			component: () => import( /* webpackChunkName: "OrderComment" */ '../views/OrderCenter/comment'),
		},
		{
			path: 'userhome/ordercenter/detail',
			name: 'OrderDetail',
			component: () => import( /* webpackChunkName: "OrderDetail" */ '../views/OrderCenter/detail'),
		},
		{
			path: 'userhome/ordercenter/PayPalSuccess',
			name: 'PayPalSuccess',
			component: () => import( /* webpackChunkName: "OrderDetail" */
				'../views/OrderCenter/PayPalSuccess'),
		},
		{
			path: 'userhome/userservice',
			name: 'UserService',
			component: () => import( /* webpackChunkName: "UserService" */ '../views/UserService'),
		},
		{
			path: 'userhome/applyinvoice',
			name: 'ApplyInvoice',
			component: () => import( /* webpackChunkName: "ApplyInvoice" */ '../views/ApplyInvoice'),
		},
		{
			path: 'userhome/applyinvoice/editinvoice',
			name: 'EditInvoice',
			component: () => import( /* webpackChunkName: "EditInvoice" */
				'../views/ApplyInvoice/invoiceEdit'),
		},
		{
			path: 'message',
			name: 'Message',
			component: () => import( /* webpackChunkName: "Message" */ '../views/Message'),
		},
		{
			path: 'userhome/customdesign',
			name: 'Design',
			component: () => import( /* webpackChunkName: "Design" */ '../views/CustomDesign'),
		},
		{
			path: 'userhome/customdesign/designrecharge',
			name: 'Recharge',
			component: () => import( /* webpackChunkName: "Recharge" */ '../views/CustomDesign/recharge'),
		},
		{
			path: 'userhome/capital',
			name: 'Capital',
			component: () => import( /* webpackChunkName: "Capital" */ '../views/Capital'),
		},
		{
			path: 'complain/complainIndex',
			name: 'ComplainIndex',
			component: () => import( /* webpackChunkName: "Quality" */ '../views/Complain/complainIndex'),
		},
		{
			path: 'orderadmin',
			name: 'OrderAdmin',

			component: () => import( /* webpackChunkName: "OrderAdmin" */ '../views/OrderAdmin'),
		},
		{
			path: 'custom',
			name: 'Custom',
			component: () => import( /* webpackChunkName: "Custom" */ '../views/Custom'),
		},
		{
			path: 'custom/confirm',
			name: 'CustomConfirm',
			component: () => import( /* webpackChunkName: "CustomConfirm" */ '../views/Custom/confirm'),
		},
		{
			path: 'custom/order',
			name: 'CustomOrder',
			component: () => import( /* webpackChunkName: "CustomOrder" */ '../views/Custom/order'),
		},
		{
			path: 'chat/index',
			name: 'chat',
			component: () => import( /* webpackChunkName: "CustomOrder" */ '../views/chat/index'),
		},

	]},{
		path: '/article',
		name: 'article',
		component: () => import('../views/article'),
    }]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onComplete, onAbort) {
//   return originalPush.call(this, location, onComplete, onAbort).catch(err => err)
// }





router.beforeEach((to, from, next) => {
	// 记录上个页面路由
	store.commit("SET_PATH", from.path)

	let {
		path,
		meta
	} = to;
	// 白名单
	if (meta.noAuth) return next()
	// 获取token
	const tokenStr = getToken()
	// 没有token, 强制跳转到登录页
	if (!tokenStr) return next('/login')


	next()
	setTimeout(() => {
		ws.connectIfNotConnected();
	}, 1000)

})


export default router
