import Vue from 'vue';
import Vuex from 'vuex';
import req from "@/api/Request"
import {
	ORDER_MODULE_MPF_USER,
	ORDER_MODULE_MST_USER
} from "../../constant";

import ChatModule from "./modules/chat";


Vue.use(Vuex);




const store = new Vuex.Store({
	modules: {
		chat: ChatModule
	},

	state: {
        newMessage:'',
		fileServer: null,
		fromPath: null,
		config: null,

		// 自定义参数
		customInfo: {},
		// 用户单位
		userUnit: null,
		// 用户单位
		userAddress: null,

		// 自己设计生成的型号
		FigureNumber: {
			[ORDER_MODULE_MPF_USER]: null,
			[ORDER_MODULE_MST_USER]: null,
		},
		params: JSON.parse(localStorage.getItem('params')),
		userInfo: JSON.parse(localStorage.getItem('userInfo')),
	},
	mutations: {
		MESSAGE(state, val){
			state.newMessage = val
		},
		
		SET_FILESERVER(state, val) {
			state.fileServer = val
		},
		SET_PARAMS(state, val) {
			state.params = val
			localStorage.setItem('params', JSON.stringify(val))
		},
		SET_UERINFO(state, val) {
			state.userInfo = val
			localStorage.setItem('userInfo', JSON.stringify(val))
		},
		SET_PATH(state, val) {
			state.fromPath = val
		},
		CLEAR_CUSTOM_INFO(state) {
			for (let key in state.customInfo) {
				if (Object.hasOwnProperty(key))
					delete state.customInfo[key]
			}
		},
		SET_CUSTOM_INFO(state, payload) {

			// Object.assign(state.customInfo, payload)

			state.customInfo = {
				...state.customInfo,
				...payload
			}

		},
		SET_CONFIG(state, payload) {
			state.config = payload;
			state.fileServer = payload.fileServer[0]
		},
		SET_ADDRESS(state, payload) {
			state.userAddress = payload;
		},

	},
	actions: {
		async GetUserUnitOrCache({
			state
		}) {
			if (state.userUnit !== null) return state.userUnit;
			try {
				let res = await req.get("/client/im/user/unit/get");
				
				return (state.userUnit = res)
			} catch {
				console.error("获取用户单位出错。")
				return {};
			}
		},
		async GetFigureNumber({
			state
		}, module) {
			let value = state.FigureNumber[module];
			if (value === null) {
				try {
					let res = await req.get("/client/order/generateFigureNumber", {
						module
					})
					return (state.FigureNumber[module] = (value = res))
				} catch {
					return null;
				}
			}
			return value
		},
		async GetUserAddress({
			state
		}) {
			let value = state.userAddress;
			try {
				let res = await req.get("/client/im/user/address/default", {
					module
				})
				return (state.userAddress = (value = res))
			} catch {
				value = {};
			}
			return value;
		},
	},
	getters: {
		customInfo: state => state.customInfo,
		// calcRes: state => state.customInfo.calcRes || {},


		calcRes: state => {

			return state.customInfo.calcRes || {}
		},

		calcModel: state => state.customInfo.calcModel || {},
		fileServer: state => state.fileServer,
		config: state => state.config,
		messageMapping: state => state.chat.messageMapping,
	},
});

export default store;

