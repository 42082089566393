import Stomp from 'stompjs'
import {v1 as uuid} from "uuid"
import Vue from 'vue'

import {getToken, setToken} from "@/utils/auth";
 

/**
 * 将键值对转换成查询参数，例如{'name':'admin','age':18}转换成 'name=admin&age=18'
 * @param {Object} data 要转换的键值对
 */
function parseQueryString(data) {
  if (typeof data === "string") {
    return data;
  }
  let list = []
  for (let key in data) {
    list.push(encodeURI(key) + "=" + encodeURI(data[key]))
  }
  return list.join("&");
}

class Socket {
  params;
  url;
  onConnect;
  useToken;
  tokenKey = "token";
  isConnect = false;

  constructor(url, onConnect, params = {}, useToken = true) {
    console.log(params)
    this.url = url;
    this.onConnect = onConnect
    this.uuid = uuid();
    this.useToken = useToken;
    params.uuid = this.uuid;
    this.params = params;

  }

  connect() {
    if (this.useToken) this.params[this.tokenKey] = getToken()
    this.url += `?${parseQueryString(this.params)}`;
    let ws = new WebSocket(this.url.replace("http", "ws"))
    this.client = Stomp.over(ws)
    this.client.connect({}, (frame) => {
      this.isConnect = true
      if (this.onConnect) this.onConnect();
    }, () => this.isConnect = false)
  }

  disConnect() {
    if (this.client) {
      this.client.disconnect();
    }
  }

  subscribeAll(path, callback) {
    if (this.client) {
      this.subscribe(`/topic${path}`, callback)
    }
  }

  subscribeUser(path, callback) {
    if (this.client) {
      this.subscribe(`/user/${this.uuid}${path}`, callback)
    }
  }

  subscribe(path, callback) {
    if (this.client) {
      this.client.subscribe(path, res => {
        let result = this.transferResult(res);
        if (result) callback(result)
      })
    }
  }

  transferResult(res) {
    res = JSON.parse(res.body)
    let {code, message, result} = res;
    if (code == 200) {
      return result;
    } else {
      alert(message)
    }
  }

  getConnectState() {
    return this.isConnect;
  }
}

export default Socket