
/**
 * 商品-模块-代销品
 */
export const GOODS_MODULE_PROXY = 1;

/**
 * 商品-模块-微通道平行流-标准品
 */
export const GOODS_MODULE_MPF_STANDARD = 201;

/**
 * 商品-模块-微通道蛇形管-标准品
 */
export const GOODS_MODULE_MST_STANDARD = 301;

/**
 * 订单-模块-代销品
 */
export const ORDER_MODULE_PROXY = 1;

/**
 * 订单-模块-设计点
 */
export const ORDER_MODULE_DESIGN_POINTS = 101;

/**
 * 订单-模块-微通道平行流-标准品
 */
export const ORDER_MODULE_MPF_STANDARD = 201;

/**
 * 订单-模块-微通道平行流-自己设计
 */
export const ORDER_MODULE_MPF_USER = 202;

/**
 * 订单-模块-微通道平行流-平台设计
 */
export const ORDER_MODULE_MPF_ENGINEER = 203;

/**
 * 订单-模块-微通道蛇形管-标准品
 */
export const ORDER_MODULE_MST_STANDARD = 301;

/**
 * 订单-模块-微通道蛇形管-自己设计
 */
export const ORDER_MODULE_MST_USER = 302;

/**
 * 订单-模块-微通道蛇形管-平台设计
 */
export const ORDER_MODULE_MST_ENGINEER = 303;