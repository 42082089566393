/**
 * 字符串格式化，两种调用方式
 * <p>"我是{0}，今年{1}了".format("loogn",22);</p>
 * <p>"我是{name}，今年{age}了".format({name:"loogn",age:22});</p>
 * @param {Object} args 格式化参数，支持对象或者多个参数
 * @return {string}
 */
String.prototype.format = function (...args) {
  let result = this;

  if (args.length > 0) {
    let first = args[0];
    if (args.length == 1 && typeof args[0] == "object") {
      for (let key in first) {
        let value = args[key];
        if (value != undefined) {
          let reg = new RegExp("({" + key + "})", "g");
          result = result.replace(reg, value);
        }
      }
    } else {
      for (let i = 0; i < args.length; i++) {
        let value = args[i];
        if (value != undefined) {
          let reg = new RegExp("({)" + i + "(})", "g");
          result = result.replace(reg, value);
        }
      }
    }
  }
  return result;
}

String.format = function (s, ...args) {
  return s.format(args);
}

