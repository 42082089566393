import Vue from 'vue';


function checkLanguage(){
	let L=this.$i18n.locale=='zh-cn'?1:0;
	return   L;
}  



Vue.prototype.$checkLanguage=checkLanguage;