import Socket from './Socket'

const VueSocket = {
  /**
   * 监听的队列，还未连接时，将回调都缓存此处，连接成功后，再一一执行。
   * 如已连接，则直接执行，不经过此缓存
   */
  onConnectEventsBuffer: [],
  client: null,
  options: null,
  connecting:false,

  initIfNotExist() {
    if (!this.client) {
      this.init()
    }
  },
  connectIfNotConnected() {
    if (!this.client.getConnectState()) {
      this.connect()
    }
  },
  init() {
    this.client = new Socket(`${this.options.baseUrl}${this.options.url}`, () => this.onConnected(this), this.options.params)
  },
  onConnected(application) {
    application.connecting = false;
    // 将缓存的事件全部执行
    let buffer = application.onConnectEventsBuffer
    for (let i = 0; i < buffer.length; i++) {
      let fun = buffer[i]
      if (fun instanceof Function) fun()
    }
    buffer.splice(0, buffer.length)
  },
  addOnConnectedEvent(fun) {
    if (this.client.getConnectState()) {
      fun()
    } else {
      this.onConnectEventsBuffer.push(fun)
	  
    }
  },
  connect() {
    if(this.connecting) return;
    this.connecting = true;
    this.client.connect()
  },
  disConnect() {
    this.client.disConnect()
  },
  /**
   * 订阅广播
   * @param {string} path 路径
   * @param callback
   */
  subscribeAll(path, callback) {
    this.client.subscribeAll(path, callback)
  },
  /**
   * 订阅点对点
   * @param {string} path 路径
   * @param  callback
   */
  subscribeUser(path, callback) {

    this.client.subscribeUser(path, callback)
  },

  /**
   * 订阅
   * @param {string} path 路径
   * @param  callback
   */
  subscribe(path, callback) {
    this.client.subscribe(path, callback)
  },
  /**
   * @param {{body:string}} res
   * @returns
   */
  transferResult(res) {
    this.client.transferResult(res)
  },

  install(Vue, options) {
    let _this = this
    _this.options = options
    Vue.prototype.$ws = {
      initIfNotExist() {
        _this.initIfNotExist()
      },
      init() {
        _this.init()
      },
      onConnected() {
        _this.onConnected(_this)
      },
      addOnConnectedEvent(fun) {
        _this.addOnConnectedEvent(fun)
      },
      connect() {
        _this.connect()
      },
      disConnect() {
        _this.disConnect()
      },
      /**
       * 订阅广播
       * @param {string} path 路径
       * @param {subscribeCallback} callback
       */
      subscribeAll(path, callback) {
        _this.subscribeAll(path, callback)
      },
      /**
       * 订阅点对点
       * @param {string} path 路径
       * @param {subscribeCallback} callback
       */
      subscribeUser(path, callback) {
		  
        _this.subscribeUser(path, callback)
      },

      /**
       * 订阅
       * @param {string} path 路径
       * @param {subscribeCallback} callback
       */
      subscribe(path, callback) {
        _this.subscribe(path, callback)
      },
      /**
       * @param {{body:string}} res
       * @returns {subscribeResult}
       */
      transferResult(res) {
        _this.transferResult(res)
      }
    }


  }

}

export default VueSocket