import fetch from "@/api/fetch";

const p = "/client/im/user";
const c = "/client/im/common";


const s = "/client/chat";


export function getEmail(email, login) {
	return fetch({
		url: `${p}/send-verify-code`,
		method: "POST",
		params: {
			email,
			login
		}
	})
}

export function login(data) {
	return fetch({
		url: `${p}/login`,
		method: "POST",
		data
	})
}

export function orderInvoice(data) {
	return fetch({
		url: `${p}/login`,
		method: "POST",
		data
	})
}

export function getUserInfo() {
	return fetch({
		url: `${p}/info`,
		method: "GET"
	})
}

export function getaccoutInfo() {
	return fetch({
		url: `${p}/info`,
		method: "GET"
	})
}


export function getinvoiceInfo() {
	return fetch({
		url: `${p}/invoice`,
		method: "GET"
	})
}


export function editInvoice(datas) {
	
	return fetch({
		url: `${p}/invoice`,
		method: "put",
		data: {
			bank: datas.depositBank,
			companyName: datas.companyNameLabel,
			bankAccount: datas.bankAccount,
			dutyParagraph: datas.Enterprise_Tax,
			email: datas.email,
			address: datas.addressLabel,
			id:datas.id,
			userId:datas.userId
		}
	})
}

// 获取默认地址
export function defaultAddress(datas) {
	return fetch({
		url: `${p}/address/default`,
		method: "GET",
	})
}


// 修改用户信息
export function Changeregister(datas, inviteCode) {
	
	return fetch({
		url: `${p}/info`,
		method: 'put',
		data: {
			companyAddress: datas.companyAddress,
			companyName: datas.companyName,
			industry: datas.industry,
			realname: datas.realname,
			sex: datas.sex,
			inviteCode: inviteCode,
			position: datas.position,
			address: {
				address: datas.address.address,
				city: datas.address.city,
				country: datas.address.country,
				province: datas.address.province,
				code: datas.address.code,
				zipCode:datas.address.zipCode,
				id:datas.address.id,
				
			},
			currencyId:datas.currencyId,
			phone: datas.phone,
			invoice: {
				bank: datas.depositBank,
				companyName: datas.companyNameLabel,
				bankAccount: datas.bankAccount,
				dutyParagraph: datas.Enterprise_Tax,
				email: datas.rejectemail,
				address: datas.addressLabel,
				phone: datas.phone2,
				id:datas.address.id,
			}
		}
	})
}


//注册用户
export function register(datas, inviteCode) {
	console.log(datas)
	return fetch({
		url: `${p}/register`,
		method: 'POST',
		data: {
			address: {
				address: datas.address.address,
				city: datas.address.city,
				country: datas.address.country,
				province: datas.address.province,
				code: datas.address.code,
				zipCode:datas.address.zipCode,
				
			},
			currencyId:datas.currencyId,
			companyAddress: datas.companyAddress,
			companyName: datas.companyName,
			industry: datas.industry,
			realname: datas.realname,
			sex: datas.sex,
			inviteCode: inviteCode,
			position: datas.position,
			phone: datas.phone,
			email: datas.email,
			invoice: {
				bank: datas.depositBank,
				companyName: datas.companyNameLabel,
				bankAccount: datas.bankAccount,
				dutyParagraph: datas.Enterprise_Tax,
				email: datas.rejectemail,
				address: datas.addressLabel,
				phone: datas.phone2
			}
		}
	})
}





// 上传文件
export function uploads(datas) {
	return fetch({
		url: `${c}/upload-file`,
		method: "POST",
		params: {
			files: datas,
		}
	})
}




//发送消息
export function sendMessage(content, contentType,roomId) {
	return fetch({
		url: `${s}/message/send-message`,
		method: "POST",
		data: {
			content,
			contentType,
			roomId
		}
	})
}



// 客服聊天室id

export function kfroomId(iskefu) {
	
	if(JSON.parse(iskefu)  ){
		
		return fetch({
			url: `${s}/room/service`,
			method: "GET",
			
		})
	}else{
		return fetch({
			url: `${s}/room/custom`,
			method: "GET",		
		})
	}
	
}



// 非客服聊天室id

export function ukfroomId() {
	return fetch({
		url: `${s}/room/custom`,
		method: "GET",
		
	})
}



// 聊天记录

export function histroy(roomId) {
	return fetch({
		url: `${s}/message`,
		method: "GET",
		params:{
			roomId
		}
		
	})
}







export function updateUserUnit(data) {
  return fetch({
    url: "/client/im/user/unit/update",
    method: "POST",
    data
  })
}
