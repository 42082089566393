import bigDecimal from "js-big-decimal";
import Vue  from "vue";
   // 转换成对应货币显示
 export function transformMoney(money, currency) {
      if (money === null || money === undefined) return "";
  
      let text = `￥${bigDecimal.round(money,2)}CNY`
      if (typeof currency === "string") {
          currency = JSON.parse(currency);
      }
      if (currency && currency.currency !== "CNY") {
          text += "（" + currency.symbol + "" + bigDecimal.round(bigDecimal.multiply(money, currency.exchangeRate), 2, bigDecimal.RoundingModes.HALF_UP) + "" + currency.currency + "）"
      }
      return text;
  }
  
  
  


Vue.prototype.$bigDecimal = bigDecimal;


Vue.prototype.$transformMoney = transformMoney;

