<template>
	<div>
		<el-upload :action="url + '/client/im/common/upload-file'" :list-type="listType" name="files"
			:headers="{Authorization}" :on-success="handleSuccess" ref='upload'  :show-file-list="isList">
			<i slot="default" :class="icon"></i>
		</el-upload>
	</div>
</template>


<script>
	import baseURl from "../api/baseURl";
	import {
		mapGetters
	} from "vuex";
	import {
		getToken
	} from "@/utils/auth";

	export default {
		props: {
			listType: {
				type: String,
				default: "picture-card"
			},
			icon:{
				type:String,
				default:'el-icon-plus'
			},
			isList:{
				type:Boolean,
				default:true
			}
		},
		data() {
			return {
				url: baseURl.url,
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				imageList: [],
				Authorization: getToken()
			};
		},

		methods: {
			handleRemove(e) {
				const filePath = e.response.result[0].url;
				const i = this.imageList.findIndex(item => item.pic === filePath)
				this.imageList.splice(i, 1)
			},
			handleSuccess(e) {
				console.log(e,444)
				if(this.isList){
					var picInfo = {
						pic: e.result[0].url
					}
					this.imageList.push(picInfo)
				}else{
					this.$emit("getImage",e.result[0].url)
				}
				
			}

		}
	};
</script>
