import VueI18n from 'vue-i18n'
import Vue from "vue";

Vue.use(VueI18n)

import zh from "@/lang/zh-cn";
import en from "@/lang/en-us";

import elementEnLocale from "element-ui/lib/locale/lang/en"
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"

const DEFAULT_LANG = 'zh-cn';
const LOCALE_KEY = 'localeLanguage';
const locales = {
  "zh-cn": {...elementZhLocale, ...zh},
  "en-us": {...elementEnLocale, ...en}
};


// 创建实例 - 根据本地语言加载多语言文件
const i18n = new VueI18n({
  locale: DEFAULT_LANG, // 默认语言 可以借助cookie来设置和获取本地语言环境 从而加载不同的语言文件
  messages: locales
})
export const setupLanguage = (lang) => {
  if (lang === undefined) {
    lang = localStorage.getItem(LOCALE_KEY)
    if (!locales[lang]) {
      lang = DEFAULT_LANG
    }
  }
  localStorage.setItem(LOCALE_KEY, lang);
  Object.keys(locales).forEach(function (lang) {
    document.body.classList.remove(`lang-${lang}`);
  });
  document.body.classList.add(`lang-${lang}`);
  // document.body.setAttribute('lang', lang);
  document.querySelector('html').setAttribute('lang', lang);
  i18n.locale = lang;
  Vue.config.lang = lang;
}
setupLanguage();
window.i18n = i18n
export default i18n