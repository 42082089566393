import fetch from "@/api/fetch";

const u = "/client/im/common/";

export function complainList(code) {
  console.log(code)
  return fetch({
    url: `${u}get-dict-item-list`,
    method: "get",
    params: {code}
  })
}

export function getDictListBatch(...arr) {
  return fetch({
    method: "GET",
    url: `${u}get-dict-item-list-batch`,
    params: {codes: arr.join(",")}
  })
}


export function common() {
  return fetch({
    url: `${u}config`,
    method: "get",
  })
}

export function getBanner() {
  return fetch({
    url: `/client/cms/banner/series`,
    method: "GET",
    params: {series: "main_goods_type"}
  })
}