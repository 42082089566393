import Vue from 'vue'
import {getChatRoomsByCurrentUser, getChatMessages} from '@/api/chat/index'

function ContentFilter(item) {
  let {contentType, content} = item
  if (contentType == 1) {
    content && (item.content = content.replaceAll('\n', '<br/>'))
  } else if (contentType == 2) {
    item.order = JSON.parse(content)
  }
  return item;
}

export default {
  state: {
    roomList: null,
    messageMapping: {}
  },
  mutations: {
    SET_ROOM_LIST(state, roomList) {
      state.roomList = roomList
    },
    ADD_ROOM(state, room) {
      if (!state.roomList) state.roomList = []
      state.roomList.push(room)
    },
    UPDATE_MEMBER(state, object) {
      let {index, id, key, value} = object
      // 有房间ID 那么遍历查找赋值
      if (id) {
        for (let i = 0; i < state.roomList.length; i++) {
          let room = state.roomList[i]
          if (room.id == id) {
            Vue.set(room, key, value)
            break
          }
        }
      }
      // 否者直接取下标
      else {
        let room = state.roomList[index]
        if (room) Vue.set(room, key, value)
      }
    },
    SET_MESSAGE(state, object) {
      let {id, list} = object
      for (let i = 0; i < list.length; i++) {
        ContentFilter(list[i])
      }
      Vue.set(state.messageMapping, id, list)
    },
    HANDLE_GET_MESSAGE(state, message) {
      let id = message.roomId
      let list = state.messageMapping[id]
	  
      // 已经缓存过聊天记录的列表，直接假如聊天列表中
      if (list) {
        list.push(ContentFilter(message))
      }
      // 更新聊天室的最后聊天时间
      // this.commit('UPDATE_MEMBER', {id, key: 'lastMessageTime', value: message.createTime})

      // TODO 增加角标
      // ...
    }
  },
  actions: {
    initMemberListIfNeed({commit, state}) {
      if (!state.memberList) {
        getChatRoomsByCurrentUser().then(({result}) => {
          commit('SET_ROOM_LIST', result)
        })
      }
    },
    initMessageListIfNeed({commit, state}, id) {
      let messageList = state.messageMapping[id]
      if (!messageList) {
        getChatMessages(id).then(res => {
          commit('SET_MESSAGE', {id, list: res || []})
        })
      }
    }
  }
}