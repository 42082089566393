export const TOKEN_KEY = "Authorization"


export function getToken() {
	return localStorage.getItem(TOKEN_KEY)
}

export function setToken(token) {
	return localStorage.setItem(TOKEN_KEY, token)
}

export function removeToken() {
	localStorage.removeItem(TOKEN_KEY)
	location.reload()
}



export function save(key, value) {
	localStorage.setItem(key, JSON.stringify(value))
}


export function get(key, defaultValue = {}) {
	return JSON.parse(localStorage.getItem(key)) || defaultValue
}

// 解析电话
export function changemodile(phone) {
	var code = Number((phone.split("#")[0]).split("+")[1]);
	var phone = phone.split("#")[1];
	return {
		code,
		phone
	}
}


// 合并地址
export function changeAddress(data) {
	if (data.city) {
		var address = data.country + data.province + data.city + data.address;
	} else {
		var address = data.country + data.province + data.address;
	}

	return {
		address
	}
}


// 转化货币
export function changeCurrency(data) {
	var id = data.id;
	return {
		id
	}
}

// 合并电话
export function joinModile(countryvalue, phone) {
	var phone = '+' + countryvalue + '#' + phone;
	return {
		phone
	}
}
