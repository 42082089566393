import Vue from "vue";
import i18n from "@/plugins/i18n";

const lang = {
  "zh-cn": "ZhCn",
  "en-us": "EnUs",
}

const plugin = {
  install(vm, options) {

    vm.prototype.$langKey = (prop) => {
      return prop + lang[i18n.locale]
    }

    vm.prototype.$lang = (obj, prop) => {
      return obj[prop + lang[i18n.locale]]
    }
  }
}
Vue.use(plugin)

