import Axios from "axios"
import i18n from "@/plugins/i18n";
import {Message,Loading} from "element-ui";
import router from "@/router";
import baseURl from "./baseURl.js";
import store from '../store/index'
import {getToken, setToken} from "@/utils/auth";
import Vue from 'vue'
// 创建 axios 实例
const instance = Axios.create({
  baseURL: baseURl.url, // api base_url
  timeout: 5000, // 请求超时时间
  header: {
    'content-type': 'application/x-www-form-urlencoded',
  },
})

instance.interceptors.request.use(config => {
  config.params || (config.params = {});
  // 每个请求都加上语言
  config.params.language = i18n.locale;
  
  // 附带token
  config.headers.Authorization = getToken()

  // console.log("======================>")
  // console.log(config)
  return config;
}, error => {
  console.log("请求失败", {error})
})

instance.interceptors.response.use(response => {
  // console.log("<======================")
  // console.log(response)
  let {data, status, statusText, headers, config, request} = response;
  let {result, code, message, token} = data

  if (token) {
    // window.sessionStorage.setItem("Authorization",token)
    // 1126919503@qq.com
    setToken(token)
  }

  if (code == 200) {
    return Promise.resolve(result)
  } else if (code >= 1000) {
    return Promise.resolve(data)
  } else if (code == 500) {
    Message.error(message)
    return Promise.reject(message)
  } else if (code == 510) {
    router.push("/login")
    return Promise.reject(message)
  }  else if (code == 530) {
	  Message({
		  message: message,
		  type: "error",
		  onClose: (res) => {
		  	router.push({
		  		path: "/"
		  	}, () => {
		  		location.reload()
		  	});
		  },
	  })
    return Promise.reject(message)
  } 
  
  else {
    Message.error("系统错误")
    return Promise.reject(message)
  }
}, error => {
})

export default instance