<template>
	<div id="pc-chat" class="flex flex-column">
		<div class="pc-chat-header primary-title flex flex-col-center flex-row-between">
			<template v-if="roomId">
				<el-alert :title="iskefu? this.$t('newadd.t.客服正在为您服务'):this.$t('newadd.t.工程师正在为您服务') " type="success"
					effect="dark" show-icon :closable="false">
				</el-alert>
			</template>
			<template v-else>
				<el-alert title="暂时无在线客服可接待,请稍后再试" type="info" effect="dark" show-icon :closable="false">
				</el-alert>
			</template>
		</div>

		<div class="chat-content overflow-scroll" ref="chatContent" @scroll="chatContentScroll">
			<div class="chat-loading flex flex-row-center flex-col-center" v-if="loadingHistory && kfInfo.id">
			</div>

			<el-empty description="暂无会话消息" v-if="!chatList.length"></el-empty>

			<template v-for="(item, index) in chatList" v-else>
				<!-- 系统消息 -->
				<!-- <div></div> -->

				<template>
					<!-- 客户的消息 -->
					<div class="customer-chat chat-item" style="text-align: right" :key="index"
						:id="'chatItem' + item.id" v-if="item.fromId == userInfo.id">
						<div class="customer-chat-top primary-title">
							<span style="margin-right: 20px">ID{{ item.fromId }}</span>
							<span>{{ item.createTime }}</span>
						</div>
						<div class="huge-font" v-if="!item.contentType">
							{{ item.content }}
						</div>

						<!-- <audio :src="item.msg_center" v-if="item.msg_type == 2" controls="controls"></audio> -->

						<el-image style="width: 100px; height: 100px" :src="item.content" :preview-src-list="srcList"
							v-if="item.contentType == 1" @click="preview(item)">
						</el-image>


						<video :src="item.msg_center" alt="" v-if="item.msg_type == 4"
							:style="{ height: 200 / item.msg_length + 'px' }" controls />
					</div>
					<!-- 客服的消息 -->
					<div class="customer-chat chat-item" style="text-align: left" :id="'chatItem' + item.id"
						:key="index" v-else>
						<div class="customer-chat-top primary-title">
							<span style="margin-right: 20px">工程师</span>
							<span>{{ item.createTime }}</span>
						</div>
						<div class="huge-font" v-if="!item.contentType">
							{{ item.content }}
						</div>


						<div class="  book" :key="item.id" v-if="item.contentType == 2">
							<span style="color: #BD362F;cursor:pointer;" @click="gobook">
								您有一条系统订单
							</span>

						</div>


						<el-image style="width: 100px; height: 100px" :src="item.content" :preview-src-list="srcList"
							v-if="item.contentType == 1" @click="preview(item)">
						</el-image>
					</div>
				</template>
			</template>


			<div class="system-tips flex flex-row-center">
				<span>{{$t('newadd.t.酷灵猫客服很高兴为您服务')}} </span>
			</div>
		</div>


		<div class="chat-btm flex flex-column">
			<div class="chat-tool huge-font" style="margin-left: 20px;">
				<el-tooltip>
					<template slot="title">发送图片</template>
					<upload ref="" listType="text" icon="el-icon-picture-outline" :isList="false" @getImage="getImage">
					</upload>
				</el-tooltip>
			</div>
			<el-input type="textarea" placeholder="请输入内容" v-model="chatText" class="chat-input flex-1" resize="none">
			</el-input>
			<div class="flex flex-row-end" style="padding: 5px 10px">
				<el-button type="primary" :block="false" @click="pressEnter" style="width: 80px" :disabled="!chatText">
					发送
				</el-button>
			</div>
		</div>



	</div>
</template>

<script>
	// import socket from "./socket";
	import upload from "@/components/upload";

	import {
		mapState
	} from 'vuex';
	import {
		getToken,
	} from "@/utils/auth";
	import {
		sendMessage,
		kfroomId,
		histroy
	} from "@/api/User";
	let scrollInitPosi = 0;

	export default {
		components: {
			upload
		},
		data() {
			return {
				iskefu: true,
				kfInfo: {
					id: "11",
					username: ""
				},
				loadingHistory: true,
				hasMoreData: true,
				chatText: '',
				roomId: '',
				chatList: [],
				srcList: []
			};
		},
		computed: {
			...mapState(["Authorization", "userInfo", "newMessage"]),

		},
		watch: {
			newMessage(val) {
				this.messageCallBack(val)
			}
		},

		methods: {
			gobook() {
				this.$router.push("/orderadmin")
			},
			preview(item) {
				this.srcList = [item.content]
			},
			chatContentScroll(e) {
				if (!this.hasMoreData) return;
				const {
					scrollTop
				} = e.target;
				if (scrollInitPosi > scrollTop) {
					//向下滚动触发
					if (scrollTop <= 20 && !this.loadingHistory) {
						this.loadingHistory = true;
						this.$nextTick(() => {
							this.$refs.chatContent.scrollTop = 0;
						});
						// setTimeout(() => {
						// 	socket.page++;
						// 	socket.getChatRecords();
						// }, 1000);
					}
				}
				setTimeout(function() {
					scrollInitPosi = scrollTop;
				}, 0);
			},
			pressEnter() {
				if (!this.kfInfo.id) {
					this.$message({
						message: '当前暂无客服在线，建议拨打联系热线',
						warn: 'warning'
					});
					return;
				}

				// 发送消息
				sendMessage(this.chatText, 0, this.roomId).then(res => {
					// this.messageCallBack(res)
				}).catch(err => {
					this.$message.error(err.message);
				});
				this.chatText = "";
			},
			getImage(e) {
				sendMessage(e, 1, this.roomId).then(res => {
					this.messageCallBack(res)
				}).catch(err => {
					this.$message.error(err.message);
				});
				this.chatText = "";
			},
			room() {
				return new Promise((resolve, reject) => {
					kfroomId(this.iskefu).then(res => {
						this.roomId = res.id
						resolve(res)
					}).catch(err => {

					})
				})
			},

			gethistroy() {
				return new Promise((resolve, reject) => {
					histroy(this.roomId).then(res => {
						this.chatList = res
						resolve(res)
					}).catch(err => {

					})
				})

			},
			messageCallBack(data) {

				console.log(data, 7777)
				this.chatList.push(data);
				this.$nextTick(() => {
					this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
				});

			},

		},
		created() {
			this.iskefu = JSON.parse(this.$route.query.iskefu)
		},
		async mounted() {

			await this.room()
			await this.gethistroy()

			this.$nextTick(() => {
				this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
			});
		}

	};
</script>

<style lang="scss">
	#app {
		min-width: initial !important;
	}

	#pc-chat {
		// height: 100%;
		border: 1px solid #dddddd;
		width: 80%;
		height: 100%;

		margin: 100px auto;

		.pc-chat-header {

			color: #fff;

			img {
				width: 40px;
				height: 40px;
				background-color: #e2e2e2;
				border-radius: 50%;
				margin-right: 10px;
			}
		}

		.kf-info {
			cursor: pointer;

			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin-right: 10px;
				margin-left: 10px;
			}
		}

		.chat-loading {
			padding: 5px;
		}

		.chat-content {
			flex: 2;
			padding: 20px;
			position: relative;
			max-height: 500px;

			.chat-item {
				padding: 10px 0;
			}

			video {
				width: 200px;
			}

			img {
				cursor: pointer;
			}

			.system-tips>span {
				color: #fff;
				background-color: rgba(0, 0, 0, 0.4);
				display: inline-block;
				padding: 2px 5px;
				margin: 0 auto;
				border-radius: 2px;
				margin: 5px 0;

				span {
					color: #ddd;
					margin-left: 5px;
				}
			}
		}

		.chat-btm {
			flex: 1;
			border-top: 1px solid #dddddd;

			.chat-tool {
				height: 40px;
				line-height: 40px;
				cursor: pointer;

				i:hover {
					color: red;
				}
			}

			.chat-input {
				border: 0;
				outline: none;
				font-size: 16px;
				padding: 5px 15px;
				resize: none;

			}
		}
	}

	.customer-chat-top {
		margin-bottom: 5px;
	}
</style>
