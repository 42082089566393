<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive">
				<!-- 这里是会被缓存的视图组件！ -->
			</router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive">
			<!-- 这里是不被缓存的视图组件！ -->
		</router-view>
		<page-footer />
	</div>
</template>

<script>
	import {
		common
	} from "./api/common";
	import {
		mapMutations
	} from 'vuex'
	//import PageFooter from "./components/PageFooter";
	import OnWSConnect from '@/api/common/socket/batchSubcribe'
	export default {
		//components: {PageFooter},
		data() {
			return {}
		},
		methods: {},
		async created() {
			this.$ws.initIfNotExist();
			this.$ws.addOnConnectedEvent(OnWSConnect)
			
			try {
				// 阻塞 保证获取配置在页面加载完成前执行成功
				let res = await common();
				console.log("加载配置完成:", res);
				this.$store.commit("SET_CONFIG", res)
			} catch (e) {
				console.error("加载配置失败:", e)
			}
		},
		beforeDestroy() {
			this.$ws.disConnect()
		},
	}
</script>

<style lang="scss">
	.hover {
		cursor: pointer;
	}

	html,
	body {
		margin: 0;
		padding: 0;
		background: #fff;

	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}


	a {
		text-decoration: none;
	}

	.router-link-active {
		text-decoration: none;
	}
</style>
