import fetch from "@/api/fetch";

export function getChatRoomsByCurrentUser() {
  return fetch({
    url: `/client/chat/room/by-user`,
    method: 'get',
  })
}


export function getChatMessages(roomId) {
  return fetch({
    url: `/client/chat/message`,
    method: 'get',
    params: {roomId}
  })
}

export function getCustomRoom() {
  return fetch({
    url: `/client/chat/room/custom`,
    method: 'GET',
  })
}


export function ApiSendMessage(content, contentType, roomId) {
  return fetch({
    url: `/client/chat/message/send-message`,
    method: 'POST',
    data: {content, contentType, roomId}
  })
}

