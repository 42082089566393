import ws from './vue-socket'
import store from '@/store'

import messageCallBack from "@/views/chat/index.vue"

export default function(){

  ws.subscribeUser("/get-message", (res) => {
	 // messageCallBack.methods.messageCallBack(res).call(messageCallBack)
	 console.log(res,111)
	 store.commit("MESSAGE",res)
    // store.commit("HANDLE_GET_MESSAGE",res)
  })

  ws.subscribeUser("/add-room", (res) => {
	  
    store.commit("ADD_ROOM",res)
  })

}