export default {
    lang: "English",
    module: {
        1: "Cooling Product Catalogue",
        101: "Design Points",
        201: "Parallel MCHE -Standard Product",
        202: "Parallel MCHE - Customized Design",
        203: "Parallel MCHE – Exclusive Design",
        301: "Serpentine MCHE – Standard Product",
        302: "Serpentine MCHE – Customized Design",
        303: "Serpentine MCHE – Exclusive Design",
		401: "Tube & Fin Heat Exchanger – Standard Product",
        402: "Tube & Fin Heat Exchanger– Customized Design",
        403: "Tube & Fin Heat Exchanger – Exclusive Design",
		501: "Brazed Plate Heat Exchanger– Standard Product",
        502: "Brazed Plate Heat Exchanger – Customized Design",
        503: "Brazed Plate Heat Exchanger – Exclusive Design",
    },
	// <!-- 状态，0，10，11已支付一笔款，20已确认，30已排产，40物料已到位，50生产制作完成，60质量检验完成, -->
	// <!-- 70准备发货，80待支付二笔款（准备发货），81已支付二笔款（准备发货），90已发货，100已完成，200已取消 -->
	orderStatus: {
	    0: "To be Confirmed",
	    10: "Pending Prepaid Payment",
	    11: "Complete Prepaid Payment",
	    20: "Confirmed",
	    30: "Production Scheduled",
	    40: "Components Ready",
	    50: "Production Finished",
	    60: "QC Passed",
		70: "Ready to Ship",
		80: "Pending Balance Payment（Ready to Ship）",
		81: "Pending Balance Payment（Ready to Ship）",
		90: "Already shipped",
		100: "Canceled",
	},
    headerMenu: {
        customPlatform: ["Customized Design", "Parallel MCHE", "Serpentine MCHE", "Tube & Fin Heat Exchange", "Brazed Plate Heat Exchange","Wire-Fin Tube Heat Exchanger"],
        otherMall: "Product",//Product Catalogue
        orderManage: "Order",
        complaint: "Complaint & Suggestion",
        vip: "Membership",//Membership Club
        accountInfo: "Account Info",
        customerService: "Customer Service",
        SignInOut: "Log In/Sign up",
        SignBack: "Log Out"

    },
	pay:{
		1:'Full Payment',
		2:'Partial Payment'
	},
	need:{
		true:'Need',
		false:'No Need'
	},
	orderDetail:{
		id:'Order ID',
	    figureNumber:'Drawing No.',
		moneyFirst:'The First Payment',
		moneyOriginal:'The Original Payment',
		moneySecond:'The Second Payment',
		payFirstTime:'The First Payment Date',
		paySecondTime:'The First Payment Amount',
		payType:'Payment Type',
		phone:'Phone No.',
		purchasingCost:'Purchasing Cost',
		returnTime:'Return Date',
		requireInvoice:'Invoice Required',
		address:'The Receipt Address',
		figureNumber:'Drawing No.',
        realname:'Consignee',
		phone:'Consignee Phone No.',
		userId:'Consignee ID',
		replacePayUserId:'Payment Agent ID',
		status:'Order Status',
		userRemark:'User Remark',
		companyName:'Invoice Title',
		createTime:'Invoice Date',
		dutyParagraph:'Company Invoice No.',
		email:'Invoice Receipt E-mail Address',
		confirmReceiptTime:'Goods Receipt Date',
		address:'Shipping Address',
		figureNumber:'Drawing No.',
		contactId:'Order Coordinator ID',
		courierCompany:'Shipping Agent',
		contractEnUs:'Contract in Chinese',
		contractZhCn:'Contract in English',
		deliveryDate:'Shipment Date',
		discountAgain:'Discount for Next Order',
		engineerId:'Engineer',
		freightMoney:'Shipping Cost',
		drewInvoice:'Invoice Issued',
		insure:'是否购买保险',
		insureMoney:'购买保险金额',
		goodsMoney:'Products Amount',
		module:'Module',

	},
    main: {
        actions: {
            normal: "STANDARD PRODUCT",
            custom: "CUSTOMIZED DESIGN",
            creative: "EXCLUSIVE DESIGN",
        }
    },
    login: {
        title: "Log In/Sign up",
        disclaimer: "Disclaimer",
        coollingAgreement: "《Coolingmall Contract》",
        submit: "Submit",
        emailLabel: "E-mail",
        verifyCodeLabel: "Verification Code",
        pleaseInputEmail: "Input Your E-mail Address Please",
        pleaseInputCorrectEmail: "Input Correct E-mail Address Please",
        pleaseInputVerifyCode: "Input Verification Code Please",
        getVerifyCode: "Get Verification Code"
    },
    register: {
        accountInfo: "Account Info",
        companyNameLabel: "*Company Name",
        companyAddressLabel: "*Company Address",
        industryLabel: "Industry",
        nameLabel: "*Name",
        genderLabel: "Gender",
        pleaseSelectGender: "Gender",
        manLabel: "Male",
        womanLabel: "Female",
        positionLabel: "Position",
        phoneLabel: "*Phone No.",
        addressLabel: "*Detail Address",
		zipLabel: "*Zip Code",
        next: "Next",
        prev: "Back",
        mesCompanyEmpty: "Input Company Name Please",
        mesStrLength: "Between 3-5 Characters",
        mesCompanyAddress: "Input Company Address Please",
        mesName: "Input Your Name Please",
        mesGender: "Choose Your Gender",
        mesPosition: "Input Your Position Please",
        mesPhone: "Input Your Phone No. Please",
        messAddress: "Input Your Address Please",
        mesPhoneError: "Phone No. Incorrect",
        sentInvoice: "Invoice Title",
        Enterprise_Tax: "Company Invoice No.",
        acceptEmaill: "*Receipt E-mail",
        depositBank: "Bank Name",
        bankAccount: "Bank Account",
        EmaillTag: "E-mail Address Incorrect",
        editTxt: "Edit"
    },
    inviteCode: {
        title: "Input Invitation Code Please",
        explain: "Invitation Code owner and user can each get 0.5% gift exchange value as reward after input invitation code.",
        over: "Finished",
        prev: "Back",
        inviteCodeLabel: "Invitation Code",
        inviteCodeEmpty: "Invitation Code can’t be blank",
        inviteCodeNumber: "Invitation code should be as numbers",
        mesAlertContent: "Invitation code can be used for new account registration, if the new account start to purchase, the invitation code owner can get gift exchange value correspondingly.",
        mesAlertTitle: "Reminder",
        mesAlertConfirm: "Confirmed"
    },
    userCenter: {
        indexNav: "Home",
        score: "(Membership Points)",
        code: "Get Invitation Code>",
        coupon: "(Gift Exchange Value)",
        gift: "To Exchange Gift>",
        goodsInfo: "Item Info",
        val: "Value",
        price: "Unit Price(CNY)",
        number: "Quantity",
        clickCopy: "Click to Copy",
    },
    orderCenter: {
        orderDel: "Cancel Order",
        clickComment: "Make Comment",
        orderComment: "Order Comment",
        orderCommentDesc: "Describe Your Question",
        qualityScore: "Quality Score",
        deliveryScore: "Delivery Score",
        orderStatus: "Order Status",
        step1: 'Submit Order',
        step2: 'Payment Complete',
        step3: 'Products Shipped',
        step4: 'Waiting for Shipment',
        step5: 'Finished',
        orderStepTip1: "ear Customer, your order has been shipped, get prepared for receiving the products.",
        orderStepTip2: "If you already received products, please click confirmed to finish order.",
        orderSn: "Order No.：",
        consigneeInfo: "Consignee Info",
        consignee: "Consignee",
        consigneeAddress: "Shipping Address",
        consigneePhone: "Consignee Phone No.",
        payInfo: "Payment Info",
        payMethod: "Payment Method",
        payTime: "Payment Date",
        deliverInfo: "Shipping Info",
        deliverMethod: "Shipping Method",
        deliverTime: "Shipping Date",
        packNumber: "Packing Quantity",
        goodsName: "Product Name",
        goodsSpec: "Product Specification",
        goodsType: "Product Type",
        goodsPrice: "Unit Price(CNY)",
        goodsNumber: "Quantity",
        remindDelivery: "Remind Coolingmall to Ship",
        goodsTotal: "Total Products Amount",
        packTotal: "Total Package Amount",
        freightTotal: "Total Freight Amount",
        total: "Total",
		kefu:'Coolingmall Customer Service',
		complain:"Compliant"
		
    },
    exchangeGift: {
        giftName: "Gift Name",
        giftType: "Gift Type",
        defaultAddress: "Default Address",
        mesGiftName: "Gift Name can’t be in Blank",
        mesGiftType: "Gift Type can’t be in Blank",
        mesDefaultAddress: "Default Address can’t be in Blank",
    },
    userService: {
        phoneText: "you can call to contact Coolingmall Supervisor or Manager",
        aboutUs: "About Us",
        field: "Application Industry"
    },
    applyInvoice: {
        applyInvoice: "Apply for Invoice",
        gongpinMall: "Coolingmall Product Catalogue",
		biaozhuanMall:'Purchase Standard Product',
        orderTime: "Order Date",
        type: "Type",
        designRecharge: "Recharge Design Points",
        getOrderDetail: "Click to see order detail",
        subtotal: "subtotal",
        editContent: "Edit Contect",
        dutyParagraph: "Company Invoice No.",
        depositBank: "Bank Name",
        bankAccount: "Bank Account",
        phone: "Phone No.",
        email: "E-mail Address",
        confirmEdit: "Confirm to Edit",
        mesdutyParagraphEmpty: "Company Invoice No.is null",
        mesDepositBankEmpty: "Bank Name is Blank",
        mesBankAccountEmpty: "Bank Account is Blank",
        mesPhoneEmpty: "Phone No. is Blank",
        mesEmailEmpty: "E-mail Address is Blank",
    },
    message: {
        message: 'Message',
        notice: "Notice",
        creativeWorkshop: "Exclusive Design",
        reply: "Reply",
        platformNotice: "Coolingmall Notice"
    },
    customDesign: {
        designNumber: "Design Points",
        designTip: "for every 100USD of products purchased, 1 design point will be given for free.",
        clickRecharge: "Click to Recharge",
        rechargeAmount: "Recharge Amount",
        cardNumber: "Bank Account",
        cardPwd: "Password",
        mesRechargeAmount: "Input Amount Please",
        mesAmountNumber: "Amount is in Number",
        mesCardNumber: "Input Card Number Please",
        mesCardPwd: "Input Password Please",
        confirmPay: "Confirm to Pay",
    },
    capital: {
        buyTotal: "Purchase Amount",
        refundTotal: "Refund Amount"
    },
    navTop: {
        index: "Home",
        userhome: "Membership Club",
        usercenter: "Membership Center",
        userservice: "Membership Service",
        applyinvoice: "Apply for Invoice",
        editinvoice: "Edit Content",
        customdesign: "Design Points",
        designrecharge: "Recharge Design Points",
        capital: "Capital",
        gifts: "Gifts Exchange",
        ordercenter: "Order Center",
        comment: "Order Comment",
        detail: "Order Detail",
        account: "Account Info",
        complain: "Complaint & Suggestion",
        quality: "Quality Complaint",
        delivery: "Shipment Complaint",
        service: "Service Complaint",
        orderadmin: "Order Management",
		complainIndex:"Complaint"
    },
    operation: {
        select: "Select",
        del: "Cancel",
        confirm: "Confirm",
        confirmSubmit: "Confirm to Submit",
        pleaseSelect: "Select",
        search: "Search",
        orderNow: "Order Now",
        orderSmaple: "Purchase sample",
    },
    complain: {
        complainQuality: "quality complaint",
        complainDelivery: "shipment complaint",
        complainService: "service complaint",
        goodsOrderSn: "order No.",
        complainType: "complaint type",
        desc: "describe problem",
        mesGOS: "Input order No.",
        mesComplainType: "please select complaint type",
        mesDesc: "please describe problem",
        phone: "Phone No.",
        mesPhone: "Input phone No. please",
        aboutImage: "Picture",
        rateval: "Rating"
    },
    orderAdmin: {
        clickPay: "Click to pay",
        refundOrder: "Back to order",
        confirmReceive: "Confirm to receive products",
		elsePay: "Invite others to pay",
    },
    mall: {
        zongHe: "综合",
        typeNumber: "Type",
        price: "Price",
        size: "Size selection",
        standardOrdering: "purchase standard product",
        allCategory: "full catalogue",
        mesPleaseEnterModel: "Input Model",
        mesChooseAccordingWorkingCondition: "Choose by Capacity",
        salesVolume: "Sales",
        length: "Length",
        height: "Height",
        customDesign: "Customized Design",
        workShop: "Exclusive Design",
        mesHighToLow: "Price High to low",
        mesLowToHigh: "Price low to high",
        sold: "Sale",
        piece: "PCS",
        MinimumOrderQuantity: "MOQ",
        sales: "Sales",
        salesToLow: "Sales high to low",
        salesToHigh: "Sales low to high",
        paixu: "Sort"

    },
    goodsDetail: {
        goodsPrice: "Mass Price",
        smaplePrice: "Sample Price",
        smaplePriceValue: ["Mass Order", "Sample Order"],
        boxSize: "Package Size",
        boxSizeValue: ["Small Package", "Standard Package"],
        supplyModes: "Purchase Method",
        sale: "Sale",
        standardNember: "Standard Code",
        TransactionTime: "Production Line",
        goodsModel: "Product Module",
        num: "Purchase Quantity",
        workInfo: "Working Condition",
        packNum: "Package Size",
        goodsInfo: "Item info",
        FreightInsurance: "Transportation insurance",
        TransactionSupport: "Deal",
        InsurancePayment: "交易支持",
        Payment: "Payment Method",
        PaymentMethod: "Paypal, T/T",
        IndustrialMaterials: "工业材料",
        WorkingConditionSelection: "Choose working condition",
        ProcessType: "工业类型",
        WorkpieceType: "工况类型",
        ProcessingCycle: "Production Time",
        ProcessingTrade: "加工贸易形式",
        goodsType: "产品类型"
    },
    goodsPay: {
        size: "Specification",
        goodsInfo: "Product Info",
        goodsType: "Type",
        goodsPrice: "Unit Price",
        goodsNum: "Quantity",
        goodsSubtotal: "subtotal ",
        BrandName: "Brand"
    },
    unit: {
        day: "Day",
        piece: 'PCS',
    },

    common: {
        null: "None",
        boolean: {
            true: "Yes",
            false: "No",
        },
        actions: {
            next: "Next",
            pre: "Back",
            back: "Back",
            confirm: "OK",
            cancel: "Cancel",
            print: "Print",
            done: "Finish",
            choose: "Choose",
        },
    },
    tips: {
        notEmpty: "Cannot be empty!",
    },

    custom: {
        material: {
            "铜": "Copper",
            "铝": "Aluminium",
        },
        shap: {
            0: "Straight",
            1: "bent",
        },
        bracketPosition: {
            0: "parallel",
            1: "vertical",
        },
        hoodPosition: {
            0: 'bottom of Housing',
            1: 'Front of Housing',
        },

        page0: {
            "工况": "Fluid",
            "应用类型":"Product Application",
            "冷凝器":"Condenser",
            "散热器":"Radiator（Liquid medium）",
            "工况类型": "Standard Conditon",
            "冷媒": "Refrigerant",
            "排气温度": "Inlet/Discharge T",
            "排气压力": "Inlet/Discharge P",
            "过冷度": "Sub-cooling",
            "工质流量": "Ref. Volume Flow",
            "环境温度": "Ambient T",
            "风速": "Air Velocity",
            "风机数量": "Fan Qty",
            "输入风机曲线": "Input Fan Curve",
            "无风机": "Unknow Fan",
            "选择风机": "Select Fan",
            "自定义风机曲线": "User Define Fan Curve",
            "风量": "Air flow",
            "风压": "Air Pressure Drop",

            "风机数量范围": "Fan Qty [1,6]"
        },

        page1: {
            "芯体": "Coil",
            "长": "Length",
            "高": "Height",
            "排数": "Bank",
            "扁管": "Tube",
            "宽": "Width",
            "孔数": "Port Number",
            "产线": "Production Line",
            "FPI": "FPI",
            "翅片": "Fin",
            "计算性能": "Get Performance",
			"爆破压力": "Burst Pressure",
        },

        page2: {
            "性能": "Capacity",
            "流量": "Ref. Mass Flow",
            "冷媒降压": "Ref. Pressure Drop",
            "换热量": "Heat Load",
            "出风温度": "Air Outlet T",
            "冷媒充注量": "Charge",
            "流程": "Pass",
            "扁管数量": "Tube Qty",
            "扁管型号": "Tube Model",
            "翅片型号": "Fin Model",
        },

        page3: {
            "部件": "Install",
            "部件类型": "Component Type",
            "风罩": "Housing",
			"无": "Null",
            "支架": "Mounting Bracket",
            "风扇直径": "Fan Diameter",
            "安装风机": "Need holes to fix fan",
            "风机数量": "Fan Qty",
            "风机安装孔直径": "Distance of holes to fix fan",
            "拉铆螺母规格": "Nut Model",
            "风罩位置": "Position of mounting hole",
            "是否翻边": "W/O Collar",
            "支架位置": "Installation surface angle",
            "支架类型": "Mounting Size",
            "厚": "Thickness",
            "孔管距": "Distance A",

            "风扇直径小于等于": "The diameter of fan mast less than {0}"
        },

        page4: {
            "接管参数": "Connect",
            "材质": "Tube Material",
            "形状": "Tube Shape",
            "位置": "Tube Position",
            "进口管": "Inlet",
            "出口管": "Outlet",
            "管径": "Diameter",
            "壁厚": "Thickness",
            "扩口": "Flaring",
            "是否扩口": "W/O Flaring",
            "扩口大小": "Flaring D",
        },

        page5: {
            "其他": "Others",
            "涂层": "Coating",
            "涂层0": "Null",
            "涂层1": "TCP",
            "涂层2": "E-coating",
			"涂层3": "E-coating & Powder coating",
            "是否充氮": "W/O Nitrogen",
        },

        page6: {
            print: {
                title: "Proposal",
                priductModel: "Model",
            },
            "设计图报价": "Quotation",
            "支架规格": "Mounting Bracket Model",
        },

    },

	newadd: {
		t: {
			"首页": "Home",
			"用户": "User",
			"酷灵猫": "About us",
			"邮编": "ZIP code",
			"当前会员积分": "Member Points",
			"生成二维码": "Get Invitation code",
			"礼品券值": "Gift Exchange Points",
			"兑换礼品": "To Exchange Gift",
			"会员中心": "Membership Club",
			"自定义设计点": "Design Points",
			"当前剩余设计点": "Design Points",
			"每购买500元货品，赠送1个设计点数": "For every 100USD of products purchased, 1 design point will be given for free",
			"点击充值": "Recharge",
			"商品信息": "Product Info.",
			"值": "Value",
			"单元": "Unit",
			"数量": "Quantity",
			"请选择货币方式": "Currency",
			"请输入邮编": "Input ZIP code please",
			"地址": "Address",
			"详细地址": "Detial Address",
			"保险金额": "Insurance Amount",
			"运费": "Shipping Cost",
			"商品总金额": "Products Amount",
			"一笔款金额": "Prepaid Payment",
			"原始金额": "Original Amount",
			"二笔款金额": "Balance Payment",
			"应付总金额": "Total",
			"提交订单": "Submit Order",
			"提交订单": "Submit Order",
			"默认地址": "Default Address",
			"保险赔付": "insurance claims",
			"运险费": "Transportation Insurance",
			"我要开票": "Get Invoice",
			"订单备注": "Order Remark",
			"全款支付": "Full Payment",
			"分期支付": "Partial Payment",
			"申请开票": "Get Invoice",
			"设计点充值": "Recharge design points",
			"确认充值": "Confirm recharge",
			"我要修改": "Edit",
			"姓名": "Name",
			"电话": "Phone No.",
			"取消": "Cancle",
			"确认": "OK",
			"选择": "Choose",
			"单位预设":"User Set Units",
			"价格排序":"Sort by price",
			"品牌筛选":"Choose by brand",
			'尺寸筛选':'Choose by size',
			'长度':'Length',
			"宽度":"Width",
			"根据工况选型":"Choose by Capacity",
			"性能检测":'Get Performance',
			"选择工况":'Condition',
			"开始检测":'Get Performance',
			"所属行业(选填)":"Industry",
			"请填写所属行业(选填)":"Please select your industry.",
			"规格":'SPECIFICATION',
			"礼物名称":'Gift Name',
			"礼品型号":'Gift Models',
			"状态":'Status',
			"金额":'Amount',
			"时间":'Time',
			"温度":'Temperature',
			"体积流量":'Volume Flow',
			"压力":'Pressure',
			"质量流量":'Mass Flow',
			"速度":'Velocity',
			"质量":'Mass',
			"充注量":'Charge',
			'清空':'Clean',
			"最大长度不能超过":'The maximum length cannot be exceeded',
			"最大高度不能超过":'The maximum height cannot be exceeded',
			"格式不正确":'The format is incorrect',
			"请填写数据":"Please fill in the data",
			"内容积":"Internal Volume",
			'空气测压降':'Air Pressure Drop',
			'冷媒质量流量':"Ref Mass Flow",
			"登陆成功":"Login successful",
			  "输入代付用户":'Input Payer ID',
			  "选择支付方式":'Select payment',
			  "微信支付":'WeChat Pay',
			  "支付宝支付":'AliPay',
			  "对公账户":'Bank Account',
			  "中国银联":'UnionPay ',
			 "客服正在为您服务":'COOLING MALL Customer Service',
			 "工程师正在为您服务":' COOLING MALL Engineer',
			 "酷灵猫客服很高兴为您服务":'COOLING MALL is glad to be at your service',
			 "功能开发中":'Function under development',
			 "该手机号已经被注册":"The phone number has been registered",
			 "该邮箱已注册":'The email address is registered',
            "国家/地区":"Country/Region",
            "省":"Province/State",
            "市":"City",
			"最低价格":"Minimum",
            "最高价格":"Maximum" 
		}
	}
}